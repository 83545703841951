.leftbutton {
    margin-top: 2%;
    display:flex; 
    justify-content:flex-end; 
    width:100%; 
    padding:0;
}

.lista {
    display: block;
}

.margenAbajo {
    margin-bottom: 2%;
}

.btn:focus,.btn:active {
    box-shadow: none !important;
}

.logo {
    width: 8vh;
    object-fit: contain;
}

.login {
    width: 40%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.iniciarSesion {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.centrar {
    margin-top: 2%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.centrar2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.inputLinea {
    box-shadow: none !important;
    background: transparent;
    border: none;
    border-bottom: 1px solid #776f6f;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.contorno {
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 50vh;
}

.botonUsuario {
    color: gray;
}
.botonUsuario:hover {
    color: white;
    text-decoration: none;
  }

.flexRowFull {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.padding {
    padding: .5rem;
}

.scrollbar {
    position: relative;
    overflow: auto;
    display: block;
}

.scrollbarListas {
    position: relative;
    height: 50vh;
    overflow: auto;
    display: block;
}

.scrollbarNotificaciones {
    position: relative;
    max-height: 80vh;
    overflow: auto;
    display: block;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: white;
}

.display-4 {
    margin-top: 2vh;
    font-size: 6vh;
    margin-bottom: 2vh;
}

.lead {
    font-size: 3vh;
}

.bgImage {
    background-color: white;
}

.letraChica {
    font-size: 8pt;
    margin-bottom: 0%;
}

.letraMuyChica {
    font-size: 7pt;
    margin-bottom: 0%;
}

.derecha {
    display: flex;
    justify-content: flex-end;
}

.izquierda {
    display: flex;
    justify-content: flex-start;
}

.notificaciones {
    width: max-content;
    max-width: 90vw;
}

.btnSinBorde {
    outline: 0 !important;
}

.fondoNegro {
    background-color: black;
}

/*Estilo de scrollbar*/
/* width */
#bordeScrollbar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
#bordeScrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
}

/* Handle */
#bordeScrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
#bordeScrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 10px;
}